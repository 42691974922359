import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getSubCategory,
  updateProductData,
} from "../../../service/ecommerce.service";
import { useSelector } from "react-redux";
import Loader from "../../layout/Loader";

export default function UpdateProduct() {
  let location = useLocation();
  let navigate = useNavigate();
  const token = useSelector((state) => state.status.token.token);
  const restaurantId = useSelector((state) => state.admin.admin.restaurant);
  const [productDetails, setProductDetails] = useState();
  const [subCategoryData, setSubCategoryData] = useState();
  const [editedImage, setEditedImage] = useState();
  const [updateProduct, setUpdateProduct] = useState();
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const fetchSubCategory = () => {
    // setIsLoading(true);
    getSubCategory(token, restaurantId).then((res) => {
      setSubCategoryData(res.data);
      setIsLoading(false);
    });
  };

  const {
    register,
    setError,
    clearErrors,
    handleSubmit: handleSubmitUpdate,
    formState: { errors },
  } = useForm();

  const errorMessages = {
    sequence: {
      message: "Enter Sequence",
      pattern: {
        value: /^\d*$/,
        message: "Sequence must contain only digits",
      },
      validate: (value) =>
        parseInt(value) > 0 || "Sequence must be greater than 0",
    },
    product_name: {
      message: "Enter Product Name",
    },
    MRP: {
      message: "Enter MRP",
      pattern: {
        value: /^\d*\.?\d*$/,
        message: "Please Enter Valid MRP",
      },
      validate: (value) =>
        parseFloat(value) > 0 || "Value must be greater than 0",
    },
    price: {
      message: "Enter Price",
      pattern: {
        value: /^\d*\.?\d*$/,
        message: "Please Enter Valid Price",
      },
      validate: (value) =>
        parseFloat(value) > 0 || "Value must be greater than 0",
    },
    weight: {
      message: "Enter Weight",
      pattern: {
        value: /^\d*\.?\d*$/,
        message: "Please Enter Valid Weight",
      },
      validate: (value) =>
        parseFloat(value) > 0 || "Value must be greater than 0",
    },
    brand: {
      message: "Enter Brand",
    },
    sub_category: {
      message: "Enter Product Type",
    },
    extra: {
      message: "Write down Description",
    },
    tax_exempt: {
      message: "Select TAX",
    },
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the value is empty
    if (!value) {
      setError(name, { type: "manual", message: errorMessages[name].message });
    } else if (errorMessages[name].pattern) {
      const pattern = errorMessages[name].pattern.value;
      if (!pattern.test(value)) {
        setError(name, {
          type: "manual",
          message: errorMessages[name].pattern.message,
        });
      } else if (errorMessages[name].validate) {
        const validate = errorMessages[name].validate;
        const validationError = validate(value);
        if (validationError) {
          setError(name, { type: "manual", message: validationError });
        } else {
          clearErrors(name);
        }
      }
    } else {
      clearErrors(name);
    }

    setUpdateProduct({
      ...updateProduct,
      [name]: value,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 1);
    if (location?.state?.productDetails) {
      const initialUpdateProduct = { ...location.state.productDetails };
      setProductDetails(initialUpdateProduct);
      setUpdateProduct(initialUpdateProduct);
      setEditedImage(initialUpdateProduct.product_url);
      console.log(location?.state?.productDetails)
    }

    fetchSubCategory();
  }, [location.state.productDetails]);

  // console.log(productDetails);
  // console.log(subCategoryData);

  const updateImage = (event) => {
    const input = event.target;
    if (input.files && input.files[0]) {
      const reader = new FileReader();

      setEditedImage(input.files[0]);

      reader.onload = function (e) {
        // Update the src attribute of the displayed image
        document.getElementById("displayedImage").src = e.target.result;
        // Set the editedImage state with the new image data
      };

      // Read the chosen file as a data URL
      reader.readAsDataURL(input.files[0]);

      input.value = "";
    }
  };

  const handleUpdateImageClick = () => {
    // Trigger the click event on the file input when the displayed image is clicked
    document.getElementById("imageInputUpdate").click();
  };

  const updateData = () => {
    // console.log(updateProduct);
    let data;
    if (typeof editedImage === "string") {
      // updateProduct.status = "INACTIVE";
      data = updateProduct;
    } else {
      updateProduct.image = editedImage;
      // updateProduct.status = "INACTIVE";
      data = updateProduct;
    }

    // console.log(data);
    updateProductData(token, data).then((res) => {
      console.log(res);
      toast.success("Product Updated...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
    });

    setTimeout(() => {
      navigate("/products");
    }, 3000);
  };

  return (
    <div>
      {isLoading ? (
        // Conditional rendering of loader
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="">
            <div className="ms-2 me-3">
              <form
                id="editForm"
                data-form-label="update"
                onSubmit={handleSubmitUpdate(updateData)}
              >
                <div className="modal-content product-modal">
                  <div className="modal-header">
                    <h4 className="modal-title fw-bold fs-5">UPDATE PRODUCT</h4>
                    <button
                      type="button"
                      className="close"
                      onClick={() => window.history.back()}
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body product-data">
                    {/* <p>Some text in the modal.</p> */}
                    {productDetails && (
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <label>
                                Sequence <i className="fa fa-asterisk"></i>
                              </label>

                              <input
                                value={updateProduct.sequence}
                                {...register("sequence", {
                                  required: "Enter Sequence",
                                })}
                                onChange={handleInputChange}
                              />
                              {errors.sequence && (
                                <p className="text-danger">
                                  {errors.sequence.message}
                                </p>
                              )}
                            </td>
                            <td>
                              <label>
                                Product Name <i className="fa fa-asterisk"></i>
                              </label>

                              <input
                                value={updateProduct.product_name}
                                {...register("product_name", {
                                  required: "Enter Product Name",
                                })}
                                onChange={handleInputChange}
                              />
                              {errors.product_name && (
                                <p className="text-danger">
                                  {errors.product_name.message}
                                </p>
                              )}
                            </td>
                            <td>
                              <label>
                                MRP ( <i className="fa fa-inr"></i>{" "}
                                <i className="fa fa-asterisk"></i>)
                              </label>

                              <input
                                value={updateProduct.MRP}
                                {...register("MRP", {
                                  required: "Enter MRP",
                                  pattern: {
                                    value: /^\d*\.?\d*$/,
                                    message: "Please Enter Valid MRP",
                                  },
                                  validate: (value) =>
                                    parseFloat(value) > 0 ||
                                    "Value must be greater than 0",
                                })}
                                onChange={handleInputChange}
                              />
                              {errors.MRP && (
                                <p className="text-danger position-absolute">
                                  {errors.MRP.message}
                                </p>
                              )}
                            </td>
                            <td>
                              <label>
                                Selling Price ( <i className="fa fa-inr"></i>{" "}
                                <i className="fa fa-asterisk"></i>)
                              </label>

                              <input
                                value={updateProduct.price}
                                {...register("price", {
                                  required: "Enter Selling Price",
                                  pattern: {
                                    value: /^\d*\.?\d*$/,
                                    message: "Please Enter Valid Price",
                                  },
                                  validate: (value) =>
                                    parseFloat(value) > 0 ||
                                    "Value must be greater than 0",
                                })}
                                onChange={handleInputChange}
                              />
                              {errors.price && (
                                <p className="text-danger position-absolute">
                                  {errors.price.message}
                                </p>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label>
                                Category <i className="fa fa-asterisk"></i>
                              </label>

                              <select
                                disabled
                                className="form-select"
                                value={updateProduct.master_category}
                                {...register("master_category")}
                              >
                                <option value={updateProduct.master_category}>
                                  {updateProduct.master_category}
                                </option>
                              </select>
                              {errors.master_category && (
                                <p className="text-danger position-absolute">
                                  {errors.master_category.message}
                                </p>
                              )}
                            </td>
                            <td>
                              <label>
                                Sub Category <i className="fa fa-asterisk"></i>
                              </label>
                              <select
                                disabled
                                className="form-select"
                                value={updateProduct.category_name}
                                {...register("category_name")}
                              >
                                <option value={updateProduct.category_name}>
                                  {updateProduct.category_name}
                                </option>
                              </select>

                              {errors.category_name && (
                                <p className="text-danger position-absolute">
                                  {errors.category_name.message}
                                </p>
                              )}
                            </td>
                            <td>
                              <label>
                                Product Weight (gm){" "}
                                <i className="fa fa-asterisk"></i>
                              </label>

                              <input
                                value={updateProduct.weight}
                                {...register("weight", {
                                  required: "Enter Weight",
                                  pattern: {
                                    value: /^\d*\.?\d*$/,
                                    message: "Please Enter Valid Weight",
                                  },
                                  validate: (value) =>
                                    parseFloat(value) > 0 ||
                                    "Value must be greater than 0",
                                })}
                                onChange={handleInputChange}
                              />
                              {errors.weight && (
                                <p className="text-danger position-absolute">
                                  {errors.weight.message}
                                </p>
                              )}
                            </td>
                            <td>
                              <label>
                                TAX <i className="fa fa-asterisk"></i>
                              </label>

                              <select
                                className="form-select"
                                defaultValue={
                                  updateProduct.tax_exempt ? "true" : "false"
                                }
                                {...register("tax_exempt", {
                                  required: "Select TAX",
                                })}
                                onChange={handleInputChange}
                              >
                                <option disabled value="">
                                  Select TAX
                                </option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>
                              {errors.tax_exempt && (
                                <p className="text-danger position-absolute">
                                  {errors.tax_exempt.message}
                                </p>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label>
                                Product Image <i className="fa fa-asterisk"></i>
                              </label>

                              {/* Hidden file input */}
                              <input
                                type="file"
                                id="imageInputUpdate"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={updateImage}
                              />
                              {/* Displayed image */}
                              <div className="ms-3">
                                <img
                                  src={editedImage}
                                  id="displayedImage"
                                  alt={updateProduct.product_url}
                                  onClick={() => handleUpdateImageClick()}
                                  // {...register("editedImage", {
                                  //   required: "Add Image",
                                  // })}
                                  // data-bs-dismiss="#editSubCategoryModal"
                                  // data-bs-target="#addImageModal"
                                  // data-bs-toggle="modal"
                                  // onClick={hideSubcategoryModal}
                                />
                              </div>
                              {/* {errors.editedImage && (
                  <p className="text-danger">
                    {errors.editedImage.message}
                  </p>
                )} */}
                            </td>
                            <td>
                              <label>
                                Brand <i className="fa fa-asterisk"></i>
                              </label>

                              <input
                                value={updateProduct.brand}
                                {...register("brand", {
                                  required: "Enter Brand",
                                })}
                                onChange={handleInputChange}
                              />
                              {errors.brand && (
                                <p className="text-danger position-absolute">
                                  {errors.brand.message}
                                </p>
                              )}
                            </td>
                            <td>
                              <label>
                                Product Type <i className="fa fa-asterisk"></i>
                              </label>

                              <input
                                value={updateProduct.sub_category}
                                {...register("sub_category", {
                                  required: "Enter Product Type",
                                })}
                                onChange={handleInputChange}
                              />
                              {errors.sub_category && (
                                <p className="text-danger position-absolute">
                                  {errors.sub_category.message}
                                </p>
                              )}
                            </td>
                            <td>
                              <label>
                                Product Description{" "}
                                <i className="fa fa-asterisk"></i>
                              </label>

                              <textarea
                                value={updateProduct.extra}
                                {...register("extra", {
                                  required: "Write down Description",
                                })}
                                onChange={handleInputChange}
                              />
                              {errors.extra && (
                                <p className="text-danger position-absolute textarea">
                                  {errors.extra.message}
                                </p>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-default"
                      // data-dismiss="modal"
                      // onClick={updateData}
                    >
                      UPDATE PRODUCT
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
