import React, { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createClientSubscription,
  getPlanDetails,
  getSubscriptionDetails,
  subscriptionActiveted,
  subscriptionCancelationRequest,
  updateBusinessDetails,
} from "../../service/ecommerce.service";
import { useSelector } from "react-redux";
import Loader from "../layout/Loader";
import useRazorpay from "react-razorpay";
import axios from "axios";

export default function Subscription() {
  const token = useSelector((state) => state.status.token.token);
  const restaurantId = useSelector((state) => state.admin.admin.restaurant);

  const [planDetails, setPlanDetails] = useState([]); // Restaurant Details
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [Razorpay, RazorpayResponse] = useRazorpay();

  const fetchPlanDetails = () => {
    // setIsLoading(true);
    getPlanDetails(token, restaurantId).then((res) => {
      setPlanDetails(res.data);
      console.log(res.data);
      getSubscriptionDetails(token, restaurantId, res.data.plan.plan_id).then(
        (res) => {
          setSubscriptionDetails(res && res.data[res.data.length - 1]);
        }
      );
      setIsLoading(false);
    });
  };

  useEffect(() => {
  

    window.scrollTo(0, 1);
    fetchPlanDetails();
   
  }, []);

  console.log(planDetails);
  console.log(subscriptionDetails);

  const {
    register,
    setError,
    clearErrors,
    handleSubmit: handleSubmitUpdate,
    formState: { errors },
  } = useForm();

  // console.log(subCategoryData);

  const updateData = () => {
    console.log(subscriptionDetails);

    
    subscriptionCancelationRequest(token, subscriptionDetails.id).then(
      (res) => {
        console.log(res.data);

        toast.success(
          "Your request of cancel subscription has been sent to our support team.",
          {
            autoClose: 3000,
            style: {
              backgroundColor: "black",
              color: "white",
            },
          }
        );

        fetchPlanDetails();
      }
    );

  };

  const [response, setResponse] = useState(null);

  console.log(response);

  const [isLoading2, setIsLoading2] = useState(false);

  const handleClientSubscription = () => {
    setIsLoading2(true);
    const data = {
      plan_id: planDetails?.plan?.plan_id,
      restaurant_id: planDetails?.restaurant_id,    
    };

    console.log(data);

    createClientSubscription(token, data).then((res) => {
      console.log(res?.data);

      if (res.data) {
        const order = res?.data;

        const apiKey = "rzp_test_b4YXoasdFeVMUB"; // Razorpay API Test key

        const options = {
          key: apiKey,
          subscription_id: order.subscription_id,
          // plan_id : "plan_NYJfJaLlBEKAMk",
          customer_notify: 1,
          amount: order.paid_amount * 100,
          currency: "INR",
          name: "InstaApp",
          description: "Payment",
          image: "/assets/img/favicon.png", // Logo URL
          theme: {
            color: "#ff6f40", // Theme color
          },

          handler: function (response) {
            console.log("Payment Response:", response);
            // Process the payment response here
            if (response.razorpay_payment_id) {
              // Activeted Subscription
              subscriptionActiveted(token, order.id).then((res) => {
                console.log(res.data);
              });
              // Payment was successful
              console.log("Payment successful!");
              toast.success("Payment Successful!", {
                autoClose: 3000,
                style: {
                  backgroundColor: "black",
                  color: "white",
                },
              });
              setIsLoading2(false);
              fetchPlanDetails();
              // alert('Payment was successful!');
            } else {
              // Payment failed
              console.log("Payment failed!");
              alert("Payment failed!");
              setIsLoading2(false);
            }
          },
          modal: {
            ondismiss: function () {
              console.log("Checkout form closed");
              setIsLoading2(false);
              alert("Payment process was cancelled.");
            },
          },
        };

        const rzp1 = new Razorpay(options);

        rzp1.on("payment.failed", function (response) {
          console.log("Payment Failed:", response.error);
          setIsLoading2(false);
          alert("Payment failed. Please try again.");
        });

        rzp1.open();
      }

      
    });
  };

  return (
    <div>
      {isLoading ? (
        // Conditional rendering of loader
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="">
            <div className="ms-2 me-3">
              <form
                id="editForm"
                data-form-label="update"
                // onSubmit={handleSubmitUpdate(updateData)}
              >
                <div className="modal-content product-modal product-modal-2 ">
                  <div className="modal-header d-flex justify-content-between">
                    <h4 className="modal-title fw-bold fs-5">
                      SUBSCRIPTION DETAILS
                    </h4>
                    {/* <button
                  hidden={true}
                  type="button"
                  className={`btn-secondary-post-code btn-post-code`}
                  data-toggle="modal"
                  data-target="#addProductDetailsModal"
                  // onClick={handleConfirm}
                >
                  ADD POST CODE
                </button> */}
                  </div>
                  <div className="modal-body mt-5">
                    {/* <p>Some text in the modal.</p> */}
                    {planDetails && (
                      <div className="d-flex">
                        <div className="w-50">
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="d-flex">
                                    <div className="w-100">
                                      <label>
                                        Business Name{" "}
                                        <i className="fa fa-asterisk"></i>
                                      </label>
                                    </div>

                                    <input
                                      className="input"
                                      value={planDetails?.name}
                                      disabled
                                    />
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <div className="d-flex">
                                    <div className="w-100">
                                      <label>
                                        Plan <i className="fa fa-asterisk"></i>
                                      </label>
                                    </div>

                                    <input
                                      className="input"
                                      value={planDetails.plan?.plan_name}
                                      disabled
                                    />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="d-flex">
                                    <div className="w-100">
                                      <label>
                                        Status{" "}
                                        <i className="fa fa-asterisk"></i>
                                      </label>
                                    </div>

                                    <input
                                      className="input"
                                      value={planDetails?.status}
                                      disabled
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {/* <div className="vertical-line"></div> */}
                        <div
                          className="productDetails py-4 px-3 h-100 me-4 mt-2"
                          //  style={{maxWidth: "500px"}}
                        >
                          <p className="fs-3 fw-bolder p-2">
                            Subscription Amount :
                          </p>
                          {subscriptionDetails &&
                          subscriptionDetails.id &&
                          subscriptionDetails.status === "ACTIVATED" ? (
                            <label className="fs-5 mt-5 ms-2">
                              Thank You for paying the amount.
                              <br />
                              Your subscription will renew automatically every
                              month by recurring payment.
                            </label>
                          ) : (
                            <label className="fs-5 mt-5 ms-2">
                              Payable Amount for per Month:{" "}
                              <i className="fa fa-inr"></i>
                              {(planDetails.plan.price * 1).toLocaleString(
                                "en-IN",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                              /-
                              <br />
                              Thank you for choosing our services!
                            </label>
                          )}
                          <div className="form-check ms-2 mt-4">
                            <input
                              className="form-check-input subscription-check-box"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              {...register("isAgreed", {
                                required:
                                  "You must agree to our terms and conditions.",
                              })}
                              checked
                            />

                            <label
                              className="form-check-label pointer"
                              for="flexCheckDefault"
                            >
                              <a
                                href="https://instaapp.online/privacy-policy"
                                className="policy"
                              >
                                I Agree to the InstaApp Privacy Policy
                              </a>
                            </label>
                          </div>
                          {errors.isAgreed && (
                            <p className="text-primary position-absolute checkbox-p">
                              {errors.isAgreed.message}
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="modal-footer">
                    {subscriptionDetails &&
                    subscriptionDetails.id &&
                    subscriptionDetails.status === "ACTIVATED" ? (
                      <button
                        type="button"
                        className="btn btn-default"
                        // data-dismiss="modal"
                        onClick={() => updateData()}
                      >
                        REQUEST FOR CANCELLATION
                      </button>
                    ) : !isLoading2 ? (
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => handleClientSubscription()}
                      >
                        PAY NOW
                      </button>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn btn-default"
                        >
                          PAY NOW
                        </button>
                        <div className="loader-container3">
                          <div className="loader3"></div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
