import React from "react";
import {BrowserRouter, Routes, Route } from "react-router-dom";
import Orders from "./components/orders/Orders";
import Login from "./components/layout/Login";
import Ecommerce from "./components/Ecommerce";
import Category from "./components/category/Category";
import SubCategory from "./components/subCategory/SubCategory";
import ProductDetails from "./components/orders/pages/OrderDetails";
import NewOrders from "./components/orders/pages/NewOrders";
import AcceptedOrders from "./components/orders/pages/AcceptedOrders";
import DeliveredOrders from "./components/orders/pages/DeliveredOrders";
import CancelledOrders from "./components/orders/pages/CancelledOrders";
import TrackOrder from "./components/orders/pages/TrackOrder";
import Products from "./components/products/Products";
import UpdateProduct from "./components/products/pages/UpdateProduct";
import AddProduct from "./components/products/pages/AddProduct";
import AddAddonProduct from "./components/products/pages/AddAddonProduct";
import AddonProduct from "./components/products/pages/AddonProduct";
import Specification from "./components/products/pages/Specification";
import Coupons from "./components/coupons/Coupons";
import Setup from "./components/setup/Setup";
import Subscription from "./components/subscription/Subscription";
import Support from "./components/support/Support";
import OrderDetails from "./components/orders/pages/OrderDetails";

export default function Router() {
  return (
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<Login />} />

          <Route path="/" element={<Ecommerce />}>


            <Route path="/orders" element={<Orders />}>
              <Route path="/orders/new" element={<NewOrders />} />
              <Route
                path="/orders/ready"
                element={<AcceptedOrders />}
              />
              <Route
                path="/orders/delivered"
                element={<DeliveredOrders />}
              />
              <Route
                path="/orders/canceled"
                element={<CancelledOrders />}
              />
            </Route>
            <Route
              path="/order-details"
              element={<OrderDetails />}
            />
            <Route path="/track-order" element={<TrackOrder />} />
            <Route path="/category" element={<Category />} />
            <Route path="/sub-category" element={<SubCategory />} />
            <Route path="/products" element={<Products />} />
            <Route path="/update-product" element={<UpdateProduct />} />
            <Route path="/add-product" element={<AddProduct />} />
            <Route path="/addon-product" element={<AddonProduct />} />
            <Route path="/add-addon-product" element={<AddAddonProduct />} />
            <Route path="/specification" element={<Specification />} />
            <Route path="/coupons" element={<Coupons />} />
            <Route path="/setup" element={<Setup />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/support" element={<Support />} />

          </Route>
        </Routes>
      </BrowserRouter>
  );
}
