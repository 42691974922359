import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addNewProduct,
  getMasterCategory,
  getSubCategory,
} from "../../../service/ecommerce.service";
import { useSelector } from "react-redux";
import Loader from "../../layout/Loader";

export default function AddProduct() {
  let location = useLocation();
  let navigate = useNavigate();
  const token = useSelector((state) => state.status.token.token);
  const restaurantId = useSelector((state) => state.admin.admin.restaurant);
  const [subCategoryData, setSubCategoryData] = useState();
  const [uploadImage, setUploadImage] = useState(null);
  const [masterCategory, setMasterCategory] = useState();
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const fetchCategory = () => {
    // setIsLoading(true);
    getSubCategory(token, restaurantId).then((res) => {
      setSubCategoryData(res.data);
    });
    getMasterCategory(token, restaurantId).then((res) => {
      setMasterCategory(res.data);
      setIsLoading(false);
    });
  };

  // console.log(subCategoryData);
  // console.log(masterCategory);

  useEffect(() => {
    window.scrollTo(0, 1);
    fetchCategory();
  }, []);

  const {
    register,
    setError,
    reset,
    handleSubmit: handleSubmitAdd,
    formState: { errors },
  } = useForm();

  const [selectedCategoryFor, setSelectedCategoryFor] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  const masterCategoryNames =
    masterCategory &&
    masterCategory.map((category) => category.master_category_name);

  const filteredSubCategories =
    subCategoryData &&
    subCategoryData.filter(
      (subCategory) =>
        selectedCategoryFor &&
        selectedCategoryFor.includes(subCategory.category_for)
    );

  const findCategoryId = (category, categoryFor, subCategoryData) => {
    const foundCategory = subCategoryData.find(
      (item) => item.category === category && item.category_for === categoryFor
    );
    return foundCategory ? foundCategory.category_id : null;
  };

  const categoryId =
    selectedSubCategory &&
    findCategoryId(selectedSubCategory, selectedCategoryFor, subCategoryData);

  // console.log(categoryId);

  const handleCategoryForChange = (event) => {
    setSelectedCategoryFor(event.target.value);
    setError("master_category", { type: "manual", message: "" });
    reset({ category_name: "" });
  };

  const handleSubCategoryChange = (event) => {
    setSelectedSubCategory(event.target.value);
    setError("category_name", { type: "manual", message: "" });
  };

  // console.log(subCategoryData);
  // console.log(masterCategory);

  const addImage = (event) => {
    const input = event.target;
    if (input.files && input.files[0]) {
      const reader = new FileReader();

      setUploadImage(input.files[0]);

      reader.onload = function (e) {
        // Update the src attribute of the displayed image
        document.getElementById("displayedImageAdd").src = e.target.result;

        setError("uploadImage", { type: "manual", message: "" });
      };

      // Read the chosen file as a data URL
      reader.readAsDataURL(input.files[0]);
    }
  };

  const handleAddImageClick = () => {
    // Trigger the click event on the file input when the displayed image is clicked
    document.getElementById("imageInputAdd").click();
  };

  const addData = (item) => {
    // console.log(item);
    // console.log(uploadImage);
    // console.log(selectedMasterCategory);

    const data = {
      product_name: item.product_name,
      price: item.price,
      caption: "NA",
      tax_exempt: item.tax_exempt,
      extra: item.extra,
      restaurant: restaurantId,
      status: "INACTIVE",
      category: categoryId,
      sequence: item.sequence,
      image: uploadImage,
      brand: item.brand,
      sub_category: item.sub_category,
      MRP: item.MRP,
      weight: item.weight,
    };

    // console.log(data);

    addNewProduct(token, data).then((res) => {
      // console.log(res.data);

      toast.success("Product Added...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });

      reset();
    });
    reset({ uploadImage: true });
    setUploadImage(null);
    setTimeout(() => {
      navigate("/products");
    }, 3000);
  };

  return (
    <div>
      {isLoading ? (
        // Conditional rendering of loader
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="">
            <div className="ms-2 me-3">
              <form
                id="editForm"
                data-form-label="update"
                onSubmit={handleSubmitAdd(addData)}
              >
                <div className="modal-content product-modal">
                  <div className="modal-header">
                    <h4 className="modal-title fw-bold fs-5">ADD PRODUCT</h4>
                    <button
                      type="button"
                      className="close"
                      onClick={() => window.history.back()}
                    >
                      &times;
                    </button>
                  </div>
                  <div className="modal-body product-data">
                    {/* <p>Some text in the modal.</p> */}
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <label>
                              Sequence <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                              {...register("sequence", {
                                required: "Enter Sequence",
                              })}
                            />
                            {errors.sequence && (
                              <p className="text-danger">
                                {errors.sequence.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Product Name <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                              {...register("product_name", {
                                required: "Enter Product Name",
                              })}
                            />
                            {errors.product_name && (
                              <p className="text-danger">
                                {errors.product_name.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              MRP ( <i className="fa fa-inr"></i>{" "}
                              <i className="fa fa-asterisk"></i>)
                            </label>

                            <input
                              {...register("MRP", {
                                required: "Enter MRP",
                                pattern: {
                                  value: /^\d*\.?\d*$/,
                                  message: "Please Enter Valid MRP",
                                },
                                validate: (value) =>
                                  parseFloat(value) > 0 ||
                                  "Value must be greater than 0",
                              })}
                            />
                            {errors.MRP && (
                              <p className="text-danger position-absolute">
                                {errors.MRP.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Selling Price ( <i className="fa fa-inr"></i>{" "}
                              <i className="fa fa-asterisk"></i>)
                            </label>

                            <input
                              {...register("price", {
                                required: "Enter Selling Price",
                                pattern: {
                                  value: /^\d*\.?\d*$/,
                                  message: "Please Enter Valid Price",
                                },
                                validate: (value) =>
                                  parseFloat(value) > 0 ||
                                  "Value must be greater than 0",
                              })}
                            />
                            {errors.price && (
                              <p className="text-danger position-absolute">
                                {errors.price.message}
                              </p>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>
                              Category <i className="fa fa-asterisk"></i>
                            </label>

                            <select
                              id="categoryFor"
                              value={selectedCategoryFor}
                              className="form-select"
                              {...register("master_category", {
                                required: "Enter Category",
                              })}
                              onChange={handleCategoryForChange}
                            >
                              <option value="" disabled>
                                Select Category
                              </option>
                              {masterCategoryNames &&
                                masterCategoryNames.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                            </select>
                            {errors.master_category && (
                              <p className="text-danger position-absolute">
                                {errors.master_category.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Sub Category <i className="fa fa-asterisk"></i>
                            </label>
                            <select
                              id="category"
                              value={selectedSubCategory}
                              className="form-select"
                              {...register("category_name", {
                                required: "Enter Sub Category",
                              })}
                              onChange={handleSubCategoryChange}
                            >
                              <option value="" disabled>
                                Select Sub Category
                              </option>
                              {filteredSubCategories &&
                                filteredSubCategories.map((category) => (
                                  <option
                                    key={category.category_id}
                                    value={category.category}
                                  >
                                    {category.category}
                                  </option>
                                ))}
                            </select>

                            {errors.category_name && (
                              <p className="text-danger position-absolute">
                                {errors.category_name.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Product Weight (gm){" "}
                              <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                              {...register("weight", {
                                required: "Enter Weight",
                                pattern: {
                                  value: /^\d*\.?\d*$/,
                                  message: "Please Enter Valid Weight",
                                },
                                validate: (value) =>
                                  parseFloat(value) > 0 ||
                                  "Value must be greater than 0",
                              })}
                            />
                            {errors.weight && (
                              <p className="text-danger position-absolute">
                                {errors.weight.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              TAX <i className="fa fa-asterisk"></i>
                            </label>

                            <select
                              className="form-select"
                              defaultValue=""
                              {...register("tax_exempt", {
                                required: "Select TAX",
                              })}
                            >
                              <option disabled value="">
                                Select TAX
                              </option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                            {errors.tax_exempt && (
                              <p className="text-danger position-absolute">
                                {errors.tax_exempt.message}
                              </p>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label>
                              Product Image <i className="fa fa-asterisk"></i>
                            </label>

                            {/* Hidden file input */}
                            <input
                              type="file"
                              id="imageInputAdd"
                              accept="image/*"
                              defaultValue={uploadImage}
                              {...register("uploadImage", {
                                required: "Please Upload Image",
                              })}
                              style={{ display: "none" }}
                              onChange={addImage}
                            />

                            {/* Displayed image */}
                            <div className="ms-3">
                              <img
                                src={
                                  uploadImage ||
                                  "/assets/img/custom/uploadImage.png"
                                }
                                id="displayedImageAdd"
                                alt="Upload"
                                // display="/assets/img/custom/uploadImage.png"
                                onClick={() => handleAddImageClick()}
                                // data-bs-dismiss="#editSubCategoryModal"
                                // data-bs-target="#addImageModal"
                                // data-bs-toggle="modal"
                                // onClick={hideSubcategoryModal}
                              />
                            </div>

                            {errors.uploadImage && (
                              <p className="text-danger position-absolute img-error">
                                {errors.uploadImage.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Brand <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                              {...register("brand", {
                                required: "Enter Brand",
                              })}
                            />
                            {errors.brand && (
                              <p className="text-danger position-absolute">
                                {errors.brand.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Product Type <i className="fa fa-asterisk"></i>
                            </label>

                            <input
                              {...register("sub_category", {
                                required: "Enter Product Type",
                              })}
                            />
                            {errors.sub_category && (
                              <p className="text-danger position-absolute">
                                {errors.sub_category.message}
                              </p>
                            )}
                          </td>
                          <td>
                            <label>
                              Product Description{" "}
                              <i className="fa fa-asterisk"></i>
                            </label>

                            <textarea
                              {...register("extra", {
                                required: "Write down Description",
                              })}
                            />
                            {errors.extra && (
                              <p className="text-danger position-absolute textarea">
                                {errors.extra.message}
                              </p>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      className="btn btn-default"
                      // data-dismiss="modal"
                      // onClick={updateData}
                    >
                      ADD PRODUCT
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
