import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createCancelOrder,
  createDeliveryOrder,
  getPaymentDetails,
  getProductDetails,
  getShippingDetails,
  markOrderDelivered,
  markProductUnavailable,
  saveInvoicePdf,
  trackOrderDetails,
} from "../../../service/ecommerce.service";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-time-picker";
// import "react-time-picker/dist/TimePicker.css";
// import "react-clock/dist/Clock.css";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../layout/Loader";
// import TimePicker from "@ashwinthomas/react-time-picker-dropdown";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import html2pdf from "html2pdf.js";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// const generateTimeOptions = () => {
//   const times = [];
//   for (let hour = 0; hour < 24; hour++) {
//     const formattedTime = `${String(hour).padStart(2, "0")}:00:00`;
//     times.push(formattedTime);
//   }
//   return times;
// };

const generateTimeOptions = () => {
  const times = [];
  for (let hour = 14; hour <= 18; hour++) {
    times.push(`${String(hour).padStart(2, "0")}:00:00`);
    if (hour !== 18) {  // Don't add 18:30 as it's outside 6 PM
      times.push(`${String(hour).padStart(2, "0")}:30:00`);
    }
  }
  return times;
};


export default function OrderDetails() {
  let location = useLocation();
  let navigate = useNavigate();
  const token = useSelector((state) => state.status.token.token);
  const restaurantId = useSelector((state) => state.admin.admin.restaurant);
  const [productDetailsData, setProductDetailsData] = useState();
  const [paymentDetails, setPaymentDetails] = useState();
  const [orderDetailsData, setOrderDetailsData] = useState();
  const [waybill, setWaybill] = useState(location?.state?.orderDetails?.delhivery_obj?.waybill);
  const [confirmOrderDetails, setConfirmOrderDetails] = useState();
  const [hideAll, setHideAll] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [orderID, setOrderID] = useState();
  const [disabled, setDisabled] = useState(false);
  const [value, onChange] = useState("10:00");
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [orderDetails, setOrderDetails] = useState();
  const [reOrder, setReOrder] = useState("FALSE")

  console.log(reOrder)

  const currencySymbol = useSelector(
    (state) => state?.restaurant?.restaurant?.currency_symbol
  );
  const logo = useSelector(
    (state) => state?.restaurant?.restaurant?.compony_image
  );
  const aboutUs = useSelector(
    (state) => state?.restaurant?.restaurant?.about_us
  );
  const address = useSelector(
    (state) => state?.restaurant?.restaurant?.address
  );
  const city = useSelector((state) => state?.restaurant?.restaurant?.city);
  const state = useSelector((state) => state?.restaurant?.restaurant?.state);
  const country = useSelector(
    (state) => state?.restaurant?.restaurant?.country
  );
  const zip = useSelector((state) => state?.restaurant?.restaurant?.zip);
  const email = useSelector(
    (state) => state?.restaurant?.restaurant?.support_email
  );
  const phone = useSelector(
    (state) => state?.restaurant?.restaurant?.support_phone_number
  );

  const newAddress =
    address + ", " + city + ", " + state + ", " + country + ", " + zip;
  console.log(newAddress);

  const divRef = useRef();

  const [invoicePdf, setInvoicePdf] = useState(null);
  const [paymentID, setPaymentId] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 1);
    if (location?.state?.orderDetails) {
      setHideAll(location.state.hideAll);
      setHidden(location.state.hidden);
      setDisabled(location.state.disabled);
      setOrderID(location?.state?.orderDetails?.order?.order_id);
      getProductDetails(
        token,
        location?.state?.orderDetails?.order?.order_id
      ).then((res) => {
        setProductDetailsData(res.data);
        setOrderDetails(res.data.results);
        setOrderDetailsData(location?.state?.orderDetails);
        setWaybill(location?.state?.orderDetails?.delhivery_obj?.waybill);
        setPaymentId(location?.state?.orderDetails?.payment_id);
        setIsLoading(false);
      });
      const orderID = location?.state?.orderDetails?.order?.order_id;
      orderID &&
        getPaymentDetails(
          token,
          location.state.orderDetails.order.order_id
        ).then((res) => {
          console.log(res.data);
          setPaymentDetails(res?.data);
          setConfirmOrderDetails(
            res?.data?.complete_shipping_address &&
              JSON.parse(res?.data?.complete_shipping_address)
          );
          setIsLoading(false);
        });
    }
    fetchTrackOrder();
  }, [
    location.state.orderDetails,
    location.state.hideAll,
    location.state.hidden,
    location.state.disabled,
    location.state.orderDetails.delhivery_obj.waybill,
    token,
  ]);

  const fetchOrderDetails = () => {
    // setIsLoading(true);
    getProductDetails(token, orderID).then((res) => {
      setProductDetailsData(res.data);
      // setOrderDetailsData(location.state.orderDetails);
      setIsLoading(false);
    });
    getPaymentDetails(token, orderID).then((res) => {
      console.log(res.data);
      setPaymentDetails(res.data);

      if (res.data.subtotal <= 0) {
        // console.log("0 subtotal")

        let data = {
          is_delivery_cancel: false,
          payment_id: orderDetailsData.payment_id,
          reason_for_cancellation: "No WayBill",
        };

        // console.log(data)

        createCancelOrder(token, data).then((res) => {
          // console.log(res.data);
          toast.error("Order Canceled...!", {
            autoClose: 1500,
            style: {
              backgroundColor: "black",
              color: "white",
            },
          });
          // document.getElementById("closeCancelModal").click();
          setTimeout(() => {
            navigate("/orders/canceled");
          }, 2500);
        });
      }
      setConfirmOrderDetails(JSON.parse(res.data.complete_shipping_address));
      setIsLoading(false);
    });
  };

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  const {
    register: register1,
    handleSubmit: handleSubmitCancel,
    formState: { errors: errors1 },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmitFeeCalculation,
    formState: { errors: errors2 },
    setError: setError2,
    clearErrors: clearErrors2,
  } = useForm();

  const { handleSubmit: handleSubmitDelivered } = useForm();

  const orderConfirm = (item) => {
    setIsLoading2(true);
    // console.log(item.pickupDate);
    const date = new Date(item.pickupDate).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const parts = date.split("/");
    const formattedDate = `${parts[2]}-${parts[0].padStart(
      2,
      "0"
    )}-${parts[1].padStart(2, "0")}`;

    const time = item.pickupTime;
    const [hours, minutes] = time.split(":");
    const formattedTime = `${hours.padStart(2, "0")}:${minutes.padStart(
      2,
      "0"
    )}:00`;

    // const formattedDate = selectedDate?.toDateString(selectedDate);

    // console.log(formattedDate);

    console.log(confirmOrderDetails);

    const phone =
      currencySymbol === "₹"
        ? confirmOrderDetails.phone.split("+91")[1]
        : confirmOrderDetails.phone.split("+1")[1];

    console.log(phone);

    const data2 = {
      restaurant_id: restaurantId,
      destination_pincode: confirmOrderDetails?.pin,
      order_id: paymentDetails?.order_id,
      weight: item.boxWeight,
    };

    console.log(data2);

    getShippingDetails(token, data2).then((res) => {
      console.log(res);
      if (res?.data) {
        // clearErrors2("weight")
        setShippingCharges(res.data[0].total_amount);
        console.log(res.data[0].total_amount);
        // setIsLoading2(false)
      }
      // else {
      //   setError2("weight", {
      //     type: "manual",
      //     message: "Invalid Pincode",
      //   })
      // }
    });

    const data = {
      name: confirmOrderDetails.name,
      add: confirmOrderDetails.add,
      pin: confirmOrderDetails.pin,
      city: confirmOrderDetails.city,
      state: confirmOrderDetails.state,
      country: confirmOrderDetails.country,
      phone: phone,
      order: paymentDetails.order_id,
      shipment_width: item.boxWidth,
      shipment_height: item.boxHeight,
      weight: item.boxWeight,
      type: "placed_order",
      compony_id: restaurantId,
      pickup_date: formattedDate,
      pickup_time: formattedTime,
      re_order: reOrder
    };

    console.log(data);
    createDeliveryOrder(token, data).then((res) => {
      console.log(res.data);
      setReOrder("FALSE");
      toast.success("Order Confirmed...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      document.getElementById("closeConfirmModal").click();
      setTimeout(() => {
        navigate("/orders/ready");
      }, 2500);
      setIsLoading2(false);
    });

    // console.log(data);
  };

  const [shippingCharges, setShippingCharges] = useState(null);
  const [isLoading2, setIsLoading2] = useState(false); // State for loading indicator

  const calculateShipping = (item) => {
    setIsLoading2(true);
    console.log(item);

    const data = {
      restaurant_id: restaurantId,
      destination_pincode: confirmOrderDetails?.pin,
      order_id: paymentDetails?.order_id,
      weight: item.weight,
    };

    console.log(data);

    getShippingDetails(token, data).then((res) => {
      console.log(res);
      if (res?.data) {
        clearErrors2("weight");
        setShippingCharges(res.data[0].total_amount);
        console.log(res.data[0].total_amount);
        setIsLoading2(false);
        fetchOrderDetails();
      } else {
        setError2("weight", {
          type: "manual",
          message: "Invalid Pincode",
        });
      }
    });
  };

  const cancelOrder = (item) => {
    setIsLoading2(true);
    const waybillID =
      orderDetailsData.delhivery_obj && orderDetailsData.delhivery_obj.waybill;
    let data;
    if (waybillID) {
      data = {
        waybill: waybillID,
        is_delivery_cancel: "True",
        payment_id: orderDetailsData.payment_id,
        reason_for_cancellation: item.remark,
      };
    } else {
      data = {
        is_delivery_cancel: "False",
        payment_id: orderDetailsData.payment_id,
        reason_for_cancellation: item.remark,
      };
    }
    console.log(data);

    createCancelOrder(token, data).then((res) => {
      console.log(res.data);
      toast.error("Order Canceled...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      document.getElementById("closeCancelModal").click();
      setTimeout(() => {
        navigate("/orders/canceled");
      }, 2500);
      setIsLoading2(false);
    });

    // console.log(item);
  };

  console.log(productDetailsData);
  console.log(orderDetailsData?.status);
  console.log(orderDetails);
  console.log(paymentDetails);

  const markUnavailable = (item) => {
    console.log(item);
    const data = {
      order_id: item.order.toString(),
      company_id: item.product.restaurant.toString(),
    };

    console.log(data);

    markProductUnavailable(token, item.order_item_id.toString(), data).then(
      (res) => {
        console.log(res);
        fetchOrderDetails();
      }
    );
  };

  const [selectedDate, setSelectedDate] = useState(null);

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    // console.log(date);
    setValue("pickupDate", date);
    clearErrors(["pickupDate"]);
    setIsDatePickerOpen(false); // Close DatePicker after date selection
  };

  const handleInputClick = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };


  const handleReorder = () => {
    setIsDatePickerOpen(false);
    setReOrder("TRUE");
  }

  const handleConfirm = () => {
    setIsDatePickerOpen(false);
  };

  // const [value, onChange] = useState('10:00');

  const downloadPDF = () => {
    const element = document.getElementById("htmlToPrint");

    // Apply background color to the whole page
    element.style.backgroundColor = "#f0f9e7";

    const opt = {
      margin: 0,
      filename: `Invoice-${orderDetailsData?.order.invoice_id}`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, backgroundColor: "#f0f9e7" },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    // Preload images before rendering
    const images = element.getElementsByTagName("img");
    const promises = [];
    for (let i = 0; i < images.length; i++) {
      const img = images[i];
      if (!img.complete) {
        const promise = new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });
        promises.push(promise);
      }
    }

    Promise.all(promises)
      .then(() => {
        // Generate the PDF
        html2pdf()
          .from(element)
          .set(opt)
          .outputPdf("blob")
          .then((pdfBlob) => {
            // Convert Blob to File
            const pdfFile = new File(
              [pdfBlob],
              `Invoice-${orderDetailsData?.order.invoice_id}.pdf`,
              { type: "application/pdf" }
            );

            // Save the PDF in the state object
            setInvoicePdf(pdfFile);

            // Create FormData object
            const formData = new FormData();
            formData.append("invoice", pdfFile);

            // Send the PDF to the backend
            paymentDetails &&
              saveInvoicePdf(token, formData, paymentDetails.order_id).then(
                (res) => {
                  console.log(res);
                }
              );

            // Optionally download the PDF
            html2pdf().from(element).set(opt).save();
          });
      })
      .catch((error) => {
        console.error("Error loading images:", error);
      })
      .finally(() => {
        // Remove the background color style after PDF generation
        element.style.backgroundColor = "";
      });
  };

  const timeOptions = generateTimeOptions();

  const deliveredOrder = () => {
    setIsLoading2(true);

    const data = {
      status: "DELIVERED",
    };

    paymentID &&
      markOrderDelivered(token, paymentID, data).then((res) => {
        console.log(res);
        toast.success("Order Develired...!", {
          autoClose: 1500,
          style: {
            backgroundColor: "black",
            color: "white",
          },
        });
        setIsLoading2(false);
        document.getElementById("closeDeliveredModal").click();
        setTimeout(() => {
          navigate("/orders/delivered");
        }, 2500);
      });
  };

  console.log(productDetailsData);
  console.log(orderDetailsData);
  console.log(paymentDetails);
  console.log(orderDetails);
  console.log(confirmOrderDetails);
  console.log(waybill);

  const [trackOrderData, setTrackOrderData] = useState();
  const [trackStatus, setTrackStatus] = useState();

  const fetchTrackOrder = () => {
    // setIsLoading(true);
    waybill &&
      trackOrderDetails(token, waybill).then((res) => {
        console.log(res?.data);
        console.log(res?.data?.ShipmentData?.[0]);
        setTrackOrderData(res?.data?.ShipmentData?.[0]);
        setTrackStatus(res?.data?.ShipmentData?.[0]?.Shipment?.Status?.Status);
        setIsLoading(false);
      });
  };

  const getMinDate = () => {
    const now = new Date();
    const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5:30
    const istDate = new Date(now.getTime() + istOffset);
  
    // Check if the current time in IST is before or after 12 PM
    if (istDate.getHours() >= 12) {
      istDate.setDate(istDate.getDate() + 1); // Move to the next day
    }
  
    // Set time to the start of the day
    istDate.setHours(0, 0, 0, 0);
    return istDate;
  };
  
  // Usage
  const minDate = getMinDate();
  

  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 6);

  return (
    <div>
      {isLoading ? ( // Conditional rendering of loader
        // <div className="spinner-border text-primary" role="status">
        //   <span className="visually-hidden">Loading...</span>
        // </div>
        <Loader />
      ) : (
        // {/* <!-- START PAGE CONTENT--> */}

        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          {/* invoice download */}
          <div className="d-none">
            {/* d-none */}
            <div id="htmlToPrint" ref={divRef}>
              <div className="body2">
                <div className="container2">
                  <h2 className="fw-bold text-center">Invoice</h2>
                  <div className="header2">
                    <img
                      src="/assets/img/bamboosa/bamboosa-logo.png"
                      // src="https://storage.googleapis.com/restaurant60-dev-media/bamboosa-logo.png"
                      alt="Logo"
                      className="logo2"
                    />
                    <div className="order-info">
                      <div className="order-details">
                        <div>Order ID: #{orderDetailsData?.order.order_id}</div>
                        <div>
                          Invoice ID:{" "}
                          {orderDetailsData?.order?.invoice_id
                            ? orderDetailsData?.order?.invoice_id
                            : "INV-12345678-1a2b3456"}
                        </div>
                        <div>GST Number: 27AKMPD9694H1ZJ</div>
                        <div>
                          Date: {orderDetailsData?.created_at?.split("T")[0]}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="address-container">
                    <div className="address-box">
                      <p className="strong">
                        <strong>Billing Address:</strong>
                      </p>
                      <p className="strong">
                        {
                          orderDetailsData?.order?.billing_address_text?.split(
                            ","
                          )[0]
                        }
                      </p>
                      <p>
                        {orderDetailsData?.order?.billing_address_text
                          ?.split(",")
                          .slice(1)
                          .join(", ")}
                      </p>
                    </div>
                    <div className="address-box">
                      <p className="strong">
                        <strong>Shipping Address:</strong>
                      </p>
                      <p className="strong">
                        {
                          orderDetailsData?.order?.shipping_address_text?.split(
                            ","
                          )[0]
                        }
                      </p>
                      <p>
                        {orderDetailsData?.order?.shipping_address_text
                          ?.split(",")
                          .slice(1)
                          .join(", ")}
                      </p>
                    </div>
                  </div>
                  <table className="items-table">
                    <thead>
                      <tr>
                        <th data-label="Product Name">Product Name</th>
                        <th data-label="Product ID">Product ID</th>
                        <th data-label="Color">Color</th>
                        <th data-label="Size">Size</th>
                        <th data-label="Price including GST">
                          Price Including GST
                        </th>
                        <th data-label="Quantity">Quantity</th>
                        <th data-label="Gross Total">Gross Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderDetails &&
                        orderDetails.map(
                          (item, i) =>
                            item.order_item_status === "Available" && (
                              <tr key={i}>
                                <td data-label="Product Name">
                                  {item.product.product_name}
                                </td>
                                <td data-label="Product ID">
                                  {item.product.product_id}
                                </td>
                                <td data-label="Color">
                                  {item.color.color.split(",")[0]}
                                </td>
                                <td data-label="Size">
                                  {item.product_size.size}
                                </td>
                                <td data-label="Price with GST">
                                  {currencySymbol}
                                  {(
                                    parseFloat(item.price).toFixed(2) * 1
                                  ).toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                                <td data-label="Quantity">{item.quantity}</td>
                                <td data-label="Gross Total">
                                  {currencySymbol}
                                  {(
                                    item.quantity *
                                    parseFloat(item.price).toFixed(2)
                                  ).toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                            )
                        )}
                    </tbody>
                  </table>
                  <div className="total-info">
                    <table className="total-info-table1">
                      <tr>
                        <th>Total MRP:</th>
                        <td>
                          {currencySymbol}
                          {Number(
                            orderDetails &&
                              orderDetails
                                .reduce((accum, item) => {
                                  const mrp =
                                    item.order_item_status === "Available" &&
                                    parseFloat(item.color.MRP);
                                  const quantity =
                                    item.order_item_status === "Available" &&
                                    item.quantity;
                                  return accum + mrp * quantity;
                                }, 0)
                                .toFixed(2)
                          ).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                      <tr>
                        <th>Discount on MRP:</th>
                        <td>
                          - {currencySymbol}
                          {Number(
                            orderDetails &&
                              orderDetails
                                .reduce((accum, item) => {
                                  const mrp =
                                    item.order_item_status === "Available" &&
                                    parseFloat(item.color.MRP);
                                  const quantity =
                                    item.order_item_status === "Available" &&
                                    item.quantity;
                                  const price =
                                    item.order_item_status === "Available" &&
                                    parseFloat(item.price);
                                  return (
                                    accum + mrp * quantity - price * quantity
                                  );
                                }, 0)
                                .toFixed(2)
                          ).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                      <tr>
                        <th>Additional Discount</th>
                        <td>
                          - {currencySymbol}
                          {(paymentDetails?.discount * 1).toLocaleString(
                            "en-IN",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </td>
                      </tr>
                      {/* <tr>
                    <th>GST Number:</th>
                    <td>27AKMPD9694H1ZJ</td>
                  </tr> */}
                      <tr>
                        <th>GST (5%):</th>
                        <td>
                          {currencySymbol}
                          {Number(
                            orderDetails &&
                              orderDetails
                                .reduce((accum, item) => {
                                  const quantity =
                                    item.order_item_status === "Available" &&
                                    item.quantity;
                                  const price =
                                    item.order_item_status === "Available" &&
                                    parseFloat(item.price);
                                  const totalAmount = price * quantity;
                                  const gst = totalAmount * 0.05;
                                  return accum + gst;
                                }, 0)
                                .toFixed(2)
                          ).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                      <tr>
                        <th>Delivery Charges</th>
                        <td style={{ color: "green" }}>Free</td>
                      </tr>
                      <tr>
                        <th>Total Amount:</th>
                        <td>
                          {currencySymbol}
                          {Number(
                            orderDetails &&
                              orderDetails
                                .reduce((accum, item) => {
                                  const quantity =
                                    item.order_item_status === "Available" &&
                                    item.quantity;
                                  const price =
                                    item.order_item_status === "Available" &&
                                    parseFloat(item.price);
                                  return accum + price * quantity;
                                }, 0)
                                .toFixed(2) - paymentDetails?.discount
                          ).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="additional-info1">
                    <p>Reg Address: {newAddress}</p>
                  </div>
                  <div className="footer2">
                    <p>
                      If you have any questions, feel free to call customer care
                      at
                      {phone} or use the Contact Us section in our App, or log
                      on to{" "}
                      <a href="https://bamboosa.online/">
                        https://bamboosa.online/
                      </a>
                    </p>
                    <p>email: {email}</p>
                    <p>Thank you for your purchase!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* end */}

          <div className="ibox">
            <div className="ibox-body m-3  mx-4">
              {/*Shipping Fee Calculation */}
              <div className="container ">
                <div
                  className="modal fade"
                  id="feeCalculationModal"
                  role="dialog"
                >
                  <div className="modal-dialog">
                    {/* <!-- Fee Calculation Modal content--> */}
                    <form
                      id="feeCalculationForm"
                      data-form-label="feeCalculation"
                      onSubmit={handleSubmitFeeCalculation(calculateShipping)}
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title fw-bold">
                            SHIPPING FEE CALCULATION
                          </h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            id="closeFeeCalculation"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body">
                          <p className="fs-6 mb-4 fw-bold ms-4">
                            Please enter below details for shipping fee
                            calculation.
                          </p>
                          <div>
                            {/* <div className="d-flex justify-content-between">
                              <div>
                                <label className="ms-4">
                                  BOX Width (In cm){" "}
                                  <i className="fa fa-asterisk"></i>
                                </label>
                              </div>

                              <input
                                {...register("boxWidth", {
                                  required: "Please Enter BOX Width",
                                  pattern: {
                                    value: /^\d*\.?\d*$/,
                                    message: "Please Enter only Numbers",
                                  },
                                  validate: (value) =>
                                    parseFloat(value) > 0 ||
                                    "Value must be greater than 0",
                                })}
                                // onKeyPress={(e) => {
                                //   // Allow only numeric input
                                //   const isNumericInput = /^\d$/;
                                //   if (!isNumericInput.test(e.key)) {
                                //     e.preventDefault();
                                //   }
                                // }}
                              />
                              {errors.boxWidth && (
                                <p className="text-danger position-absolute mt-4 ms-4">
                                  {errors.boxWidth.message}
                                </p>
                              )}
                            </div> */}

                            {/* <div className="d-flex justify-content-between">
                              <div>
                                <label className="ms-4">
                                  BOX Height (In cm){" "}
                                  <i className="fa fa-asterisk"></i>
                                </label>
                              </div>

                              <input
                                {...register("boxHeight", {
                                  required: "Please Enter BOX Height",
                                  pattern: {
                                    value: /^\d*\.?\d*$/,
                                    message: "Please Enter only Numbers",
                                  },
                                  validate: (value) =>
                                    parseFloat(value) > 0 ||
                                    "Value must be greater than 0",
                                })}
                              />
                              {errors.boxHeight && (
                                <p className="text-danger position-absolute mt-4 ms-4">
                                  {errors.boxHeight.message}
                                </p>
                              )}
                            </div> */}

                            <div className="row px-4">
                              <div className="col-md-5">
                                <label>
                                  Weight (In gm){" "}
                                  <i className="fa fa-asterisk"></i>
                                </label>
                              </div>

                              <input
                                className="col-md-7"
                                {...register2("weight", {
                                  required: "Please Enter Weight",
                                  pattern: {
                                    value: /^\d*\.?\d*$/,
                                    message: "Please Enter only Numbers",
                                  },
                                  validate: (value) =>
                                    parseFloat(value) > 0 ||
                                    "Weight must be > 0",
                                })}
                              />
                              {errors2.weight && (
                                <p className="ms-1 mt-4 text-danger position-absolute">
                                  {errors2.weight.message}
                                </p>
                              )}
                            </div>
                            <div className="row px-4">
                              <div className="col-md-5">
                                <label>Shipping Charges </label>
                              </div>
                              <div className="col-md-7">
                                {shippingCharges && (
                                  <label
                                    className="fw-bold fs-5"
                                    style={{ color: "green" }}
                                  >
                                    {currencySymbol}
                                    {shippingCharges}/-
                                  </label>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          {!isLoading2 ? (
                            <button
                              type="submit"
                              className="btn btn-default"
                              // data-dismiss="modal"
                              // onClick={addData}
                            >
                              CALCULATE SHIPPING FEE
                            </button>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="btn btn-default"
                                // data-dismiss="modal"
                                // onClick={addData}
                              >
                                CALCULATE SHIPPING FEE
                              </button>
                              <div className="loader-container2">
                                <div className="loader2"></div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* Confirm Order Modal */}
              <div className="container ">
                <div
                  className="modal fade"
                  id="addProductDetailsModal"
                  role="dialog"
                >
                  <div className="modal-dialog">
                    {/* <!-- Add Product Details Modal content--> */}
                    <form
                      id="confirmOrderForm"
                      data-form-label="confirmOrder"
                      onSubmit={handleSubmit(orderConfirm)}
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title fw-bold">ADD DETAILS</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            id="closeConfirmModal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body product-details-modal">
                          <p className="fs-6 mb-4 fw-bold ms-4">
                            Are you sure you want to Confirm this Order? <br />
                            Before Order Confirmation, Please enter below
                            details.
                          </p>
                          <div>
                            <div className="d-flex justify-content-between">
                              <div>
                                <label className="ms-4">
                                  BOX Width (In cm){" "}
                                  <i className="fa fa-asterisk"></i>
                                </label>
                              </div>

                              <input
                                {...register("boxWidth", {
                                  required: "Please Enter BOX Width",
                                  pattern: {
                                    value: /^\d*\.?\d*$/,
                                    message: "Please Enter only Numbers",
                                  },
                                  validate: (value) =>
                                    parseFloat(value) > 0 ||
                                    "Value must be greater than 0",
                                })}
                                // onKeyPress={(e) => {
                                //   // Allow only numeric input
                                //   const isNumericInput = /^\d$/;
                                //   if (!isNumericInput.test(e.key)) {
                                //     e.preventDefault();
                                //   }
                                // }}
                              />
                              {errors.boxWidth && (
                                <p className="text-danger position-absolute mt-4 ms-4">
                                  {errors.boxWidth.message}
                                </p>
                              )}
                            </div>

                            <div className="d-flex justify-content-between">
                              <div>
                                <label className="ms-4">
                                  BOX Height (In cm){" "}
                                  <i className="fa fa-asterisk"></i>
                                </label>
                              </div>

                              <input
                                {...register("boxHeight", {
                                  required: "Please Enter BOX Height",
                                  pattern: {
                                    value: /^\d*\.?\d*$/,
                                    message: "Please Enter only Numbers",
                                  },
                                  validate: (value) =>
                                    parseFloat(value) > 0 ||
                                    "Value must be greater than 0",
                                })}
                              />
                              {errors.boxHeight && (
                                <p className="text-danger position-absolute mt-4 ms-4">
                                  {errors.boxHeight.message}
                                </p>
                              )}
                            </div>

                            <div className="d-flex justify-content-between">
                              <div>
                                <label className="ms-4">
                                  BOX Weight (In gm){" "}
                                  <i className="fa fa-asterisk"></i>
                                </label>
                              </div>

                              <input
                                {...register("boxWeight", {
                                  required: "Please Enter BOX Weight",
                                  pattern: {
                                    value: /^\d*\.?\d*$/,
                                    message: "Please Enter only Numbers",
                                  },
                                  validate: (value) =>
                                    parseFloat(value) > 0 ||
                                    "Value must be greater than 0",
                                })}
                              />
                              {errors.boxWeight && (
                                <p className="text-danger position-absolute mt-4 ms-4">
                                  {errors.boxWeight.message}
                                </p>
                              )}
                            </div>

                            <div className="d-flex justify-content-between">
                              <div>
                                <label className="ms-4">
                                  Pickup Date <i className="fa fa-asterisk"></i>
                                </label>
                              </div>

                              <input
                                {...register("pickupDate", {
                                  required: "Please Select Pickup Date",
                                })}
                                placeholder="Pickup Date"
                                onClick={handleInputClick}
                                value={
                                  selectedDate
                                    ? selectedDate.toLocaleDateString("en-US", {
                                        timeZone: "Asia/Kolkata",
                                        day: "numeric",
                                        month: "short",
                                        year: "numeric",
                                      })
                                    : ""
                                }
                                style={{ paddingRight: "2.5em" }}
                              />
                              <FontAwesomeIcon
                                icon={faCalendar}
                                className="calender-icon"
                                onClick={handleInputClick}
                              />

                              {isDatePickerOpen && (
                                <div className="datePicker">
                                  <DatePicker
                                    selected={selectedDate}
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    dateFormat="yyyy-MM-dd" // Customize date format if needed
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    inline
                                  />
                                </div>
                              )}

                              {errors.pickupDate && (
                                <p className="text-danger position-absolute mt-4 ms-4">
                                  {errors.pickupDate.message}
                                </p>
                              )}
                            </div>

                            <div className="d-flex justify-content-between">
                              <div>
                                <label className="ms-4">
                                  Pickup Time <i className="fa fa-asterisk"></i>
                                </label>
                              </div>
                              {/* <div className="ui container-date">
                                <div className="ui calendar" id="example16">
                                  <div className="ui input left icon">
                                    <i className="time icon"></i>  */}
                              {/* <input
                                type="text"
                                placeholder="Pickup Time"
                                {...register("pickupTime", {
                                  required: "Please Select Pickup Time",
                                })}
                                style={{ fontWeight: "normal" }}
                              /> */}
                              <select
                                {...register("pickupTime", {
                                  required: "Please Select Pickup Time",
                                })}
                                style={{ fontWeight: "normal" }}
                              >
                                <option value="">Select Pickup Time</option>
                                {timeOptions.map((time) => (
                                  <option key={time} value={time}>
                                    {time}
                                  </option>
                                ))}
                              </select>
                              {errors.pickupTime && (
                                <p className="text-danger position-absolute mt-4 ms-4">
                                  {errors.pickupTime.message}
                                </p>
                              )}
                              {/* </div>
                                </div>
                              </div> */}
                              {/* <div className="TimePicker">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["TimePicker"]}>
                                  <TimePicker
                                    className="pickup-time"
                                    label="Pickup Time"
                                    viewRenderers={{
                                      hours: renderTimeViewClock,
                                      minutes: renderTimeViewClock,
                                      seconds: renderTimeViewClock,
                                    }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </div> */}
                              {/* <div>
                            <TimePicker
        // onChange={onChange}
        // value={value}
      />
      

      
                            </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          {!isLoading2 ? (
                            <button
                              type="submit"
                              className="btn btn-default"
                              // data-dismiss="modal"
                              // onClick={updateData}
                            >
                              CONFIRM ORDER
                            </button>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="btn btn-default"
                                // data-dismiss="modal"
                                // onClick={addData}
                              >
                                CONFIRM ORDER
                              </button>
                              <div className="loader-container2">
                                <div className="loader2"></div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* Cancel Order Modal */}
              <div className="container ">
                <div className="modal fade" id="cancelOrderModal" role="dialog">
                  <div className="modal-dialog">
                    {/* <!-- Cancel Modal content--> */}
                    <form
                      id="cancelOrderForm"
                      data-form-label="cancelOrder"
                      onSubmit={handleSubmitCancel(cancelOrder)}
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title fw-bold">WARNING</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            id="closeCancelModal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body">
                          <p className="fs-6 mb-4 fw-bold">
                            Are you sure you want to Cancel this Order?
                          </p>
                          <div>
                            <div>
                              <label>
                                Add Remark <i className="fa fa-asterisk"></i>
                              </label>

                              <input
                                {...register1("remark", {
                                  required:
                                    "Please Enter Reason for Order Cancelation",
                                })}
                              />
                              {errors1.remark && (
                                <p className="text-danger position-absolute">
                                  {errors1.remark.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          {!isLoading2 ? (
                            <button
                              type="submit"
                              className="btn btn-default"
                              // data-dismiss="modal"
                              // onClick={addData}
                            >
                              CANCEL ORDER
                            </button>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="btn btn-default"
                                // data-dismiss="modal"
                                // onClick={addData}
                              >
                                CANCEL ORDER
                              </button>
                              <div className="loader-container2">
                                <div className="loader2"></div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* Delivered Order Modal */}
              <div className="container ">
                <div
                  className="modal fade"
                  id="deliveredOrderModal"
                  role="dialog"
                >
                  <div className="modal-dialog">
                    {/* <!-- Delivered Modal content--> */}
                    <form
                      id="deliveredOrderForm"
                      data-form-label="deliveredOrder"
                      onSubmit={handleSubmitDelivered(deliveredOrder)}
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title fw-bold">
                            ORDER DELIVERED
                          </h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            id="closeDeliveredModal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body">
                          <p className="fs-6 mb-4 fw-bold">
                            Are you sure this order is delivered?
                          </p>
                        </div>
                        <div className="modal-footer">
                          {!isLoading2 ? (
                            <button
                              type="submit"
                              className="btn btn-default"
                              // data-dismiss="modal"
                              // onClick={addData}
                            >
                              ORDER DELIVERED
                            </button>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="btn btn-default"
                                // data-dismiss="modal"
                                // onClick={addData}
                              >
                                ORDER DELIVERED
                              </button>
                              <div className="loader-container2">
                                <div className="loader2"></div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="row">
                {/* Product Calculation Tab */}

                <div className="product-calculation col-md-6">
                  <div className="address-box2 mb-5">
                    <p className="strong px-2">
                      <strong>Shipping Address :</strong>{" "}
                      {
                        orderDetailsData?.order?.shipping_address_text?.split(
                          ","
                        )[0]
                      }
                    </p>

                    <p className="px-2">
                      {orderDetailsData?.order?.shipping_address_text
                        ?.split(",")
                        .slice(1)
                        .join(", ")}
                    </p>
                    <p className="px-2">
                      <strong>Pincode :</strong> {confirmOrderDetails?.pin}
                    </p>
                    {waybill ? (
                      <p className="px-2">
                        <strong>waybill :</strong> {waybill}
                      </p>
                    ) : null}
                    {waybill ? (
                      <p className="px-2">
                        <strong>Order Status :</strong>{" "}
                        <label
                          className="px-2 py-1"
                          style={{
                            background: "#ff6f40",
                            color: "white",
                            fontWeight: "600",
                          }}
                        >
                          {trackStatus}
                        </label>
                      </p>
                    ) : null}
                    {paymentDetails?.shipping_fee > 0 && (
                      <p className="p-2" style={{ background: "#f4efed" }}>
                        <strong>
                          Shipping Charges : {currencySymbol}
                          {paymentDetails?.shipping_fee}
                        </strong>
                      </p>
                    )}
                  </div>
                  <div className="">
                    <div>
                      {paymentDetails && (
                        <table className="billing" style={{ width: "100%" }}>
                          <tr>
                            <td>Sub Total</td>
                            <td>:</td>
                            <td>
                              <i className="fa fa-inr"></i>
                              {(paymentDetails.subtotal * 1).toLocaleString(
                                "en-IN",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </td>
                          </tr>
                          {/* <tr>
                            <td>TAX</td>
                            <td>:</td>
                            <td>
                              <i className="fa fa-inr"></i>
                              {paymentDetails.tax}
                            </td>
                          </tr> */}
                          {/* <tr>
                            <td>TIP</td>
                            <td>:</td>
                            <td>
                              <i className="fa fa-inr"></i>
                              {paymentDetails.tip}
                            </td>
                          </tr> */}
                          {/* <tr>
                            <td>Shipping Fee</td>
                            <td>:</td>
                            <td>
                              <i className="fa fa-inr"></i>
                              {(paymentDetails.shipping_fee * 1).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                            </td>
                          </tr> */}
                          <tr>
                            <td>Discount</td>
                            <td>:</td>
                            <td>
                              <i className="fa fa-inr"></i>
                              {(paymentDetails.discount * 1).toLocaleString(
                                "en-IN",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </td>
                          </tr>
                          {/* <hr/> */}
                          <div className="line"></div>
                          <tr className="fw-bolder">
                            <td>Order Total</td>
                            <td>:</td>
                            <td>
                              <i className="fa fa-inr"></i>
                              {(
                                paymentDetails.subtotal -
                                paymentDetails.discount
                              ).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          </tr>

                          {/* <Watermark /> */}
                        </table>
                      )}
                      <div className={`${hideAll ? "hidden" : ""}`}>
                        <div
                          className={`row confirm-cancel`}
                          style={{ width: "90%" }}
                        >
                          {orderDetailsData?.status === "ORDER_PLACED" ? (
                            <div className="col-lg-6">
                              <button
                                hidden={hidden}
                                type="button"
                                className={`btn-confirm btn-secondary-confirm`}
                                data-toggle="modal"
                                data-target="#addProductDetailsModal"
                                onClick={handleConfirm}
                              >
                                CONFIRM
                              </button>
                            </div>
                          ) : null}

                          {trackStatus === "Not Picked" && orderDetailsData?.status === "CONFIRMED" ? (
                            <div className="col-lg-6">
                              <button
                                type="button"
                                className={`btn-confirm btn-secondary-confirm`}
                                data-toggle="modal"
                                data-target="#addProductDetailsModal"
                                onClick={handleReorder}
                              >
                                RE-ORDER
                              </button>
                            </div>
                          ) : orderDetailsData?.status === "CONFIRMED" ? (
                            <div className="col-lg-6">
                              <button
                                type="button"
                                className={`btn-confirm btn-secondary-confirm`}
                                data-toggle="modal"
                                data-target="#deliveredOrderModal"
                                onClick={handleConfirm}
                              >
                                DELIVERED
                              </button>
                            </div>
                          ) : null}

                          <div
                            // className={`${hidden ? "col-lg-12" : "col-lg-6"}`}
                            className="col-lg-6"
                          >
                            <button
                              type="button"
                              className={`btn-confirm btn-secondary-cancel`}
                              data-toggle="modal"
                              data-target="#cancelOrderModal"
                            >
                              CANCEL
                            </button>
                          </div>

                          <div className="col-lg-12">
                            <button
                              type="button"
                              className={`btn-confirm btn-secondary-confirm1`}
                              data-toggle="modal"
                              data-target="#feeCalculationModal"
                            >
                              CALCULATE SHIPPING FEE
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Product Details Tab */}
                <div className="productDetails col-md-6">
                  <div className="">
                    <div className="productDetails-top row">
                      <label className="fs-4 fw-bold px-3 pt-3 orderid col-lg-6">
                        Order ID: {orderDetailsData?.order?.order_id}
                      </label>
                      {orderDetailsData && (
                        <label className=" float-right fs-4 fw-bold px-3 col-lg-6">
                          <button
                            // hidden={hidden}
                            type="button"
                            className={`btn-confirm btn-secondary-confirm1`}
                            onClick={downloadPDF}
                          >
                            DOWNLOAD INVOICE
                          </button>
                        </label>
                      )}
                    </div>

                    <div className="mb-2 p-3">
                      {paymentDetails && (
                        <small className="text-muted">
                          {new Date(paymentDetails.created_at).toLocaleString(
                            "en-US",
                            {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                              // hour: "numeric",
                              // minute: "numeric",
                              // second: 'numeric',
                            }
                          )}
                        </small>
                      )}
                      {productDetailsData &&
                        productDetailsData.results.map((item, i) => (
                          <div className="card mb-2 w-100 h-25 mt-2" key={i}>
                            <div className="row g-0">
                              <div className="col-lg-4">
                                <img
                                  src={item.color.image_url.split("@")[0]}
                                  className="img-fluid rounded-start h-100 p-1"
                                  alt="..."
                                />
                              </div>
                              <div className="col-lg-8">
                                <div className="card-body">
                                  <h5 className="card-title fs-5">
                                    {item.product.product_name}
                                  </h5>
                                  <div className="card-text">
                                    <div className="d-flex justify-content-between">
                                      <div>
                                        <div>
                                          <label>
                                            Size: {item.color.size.name}
                                          </label>
                                        </div>
                                        <div>
                                          <label>
                                            Color:{" "}
                                            {item.color.color.split(",")[0]}
                                          </label>
                                        </div>
                                      </div>

                                      <div>
                                        <div>
                                          <label>
                                            Quantity: {item.quantity}
                                          </label>
                                        </div>
                                        <div>
                                          <label>
                                            Price: <i className="fa fa-inr"></i>
                                            {(
                                              item.color.price * 1
                                            ).toLocaleString("en-IN", {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            })}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-text d-flex justify-content-between mt-4">
                                    <div>
                                      <label className="fw-bold">
                                        Total: <i className="fa fa-inr"></i>
                                        {(
                                          item.color.price * item.quantity
                                        ).toLocaleString("en-IN", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                      </label>
                                    </div>

                                    <div>
                                      <button
                                        disabled={
                                          disabled
                                            ? disabled
                                            : item.order_item_status ===
                                              "Available"
                                            ? false
                                            : true
                                        }
                                        type="button"
                                        className={`btn btn-light btn-sm fw-bold text-danger mt-1
                                       ${
                                         hidden
                                           ? item.order_item_status ===
                                             "Available"
                                             ? "hidden"
                                             : ""
                                           : ""
                                       }
                                      `}
                                        onClick={() => markUnavailable(item)}
                                      >
                                        {item.order_item_status === "Available"
                                          ? "Mark as Unavailable"
                                          : "Currently Unavailable"}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
