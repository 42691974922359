import React, { useEffect, useState } from "react";
import update from "immutability-helper";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactPaginate from "react-paginate";
import "react-toastify/dist/ReactToastify.css";
import {
  getAllProducts,
  updateProductData,
  updateProductSequence,
} from "../../service/ecommerce.service";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../layout/Loader";

export default function Products() {
  const token = useSelector((state) => state.status.token.token);
  const restaurantId = useSelector((state) => state.admin.admin.restaurant);
  let navigate = useNavigate();

  const [productsData, setProductsData] = useState([]);
  const [isButtonActive, setIsButtonActive] = useState(false); // Added state for button
  const [page, setPage] = useState(1);
  const [count, setCount] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  // console.log(productsData[0]);

  // Function to toggle button's active state
  const toggleButton = () => {
    setIsButtonActive(!isButtonActive);
  };

  const fetchProducts = () => {
    // setIsLoading(true);
    getAllProducts(token, page, restaurantId).then((res) => {
      setProductsData(res.data.results);
      setCount(res.data.count);
      setIsLoading(false);
    });
  };

  // console.log(productsData);
  //   console.log(count);
  //   console.log(page);

  useEffect(() => {
    window.scrollTo(0, 1);
    fetchProducts();
    // eslint-disable-next-line
  }, []);

  const itemsPerPage = 30;
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const moveRow = (dragIndex, hoverIndex) => {
    const startIndex = currentPage * itemsPerPage;
    const updatedDragIndex = startIndex + dragIndex;
    const updatedHoverIndex = startIndex + hoverIndex;

    const draggedRow = productsData[updatedDragIndex];
    const updatedOrders = update(productsData, {
      $splice: [
        [updatedDragIndex, 1],
        [updatedHoverIndex, 0, draggedRow],
      ],
    });

    setProductsData(updatedOrders);

    const data = updatedOrders.map((order, i) => ({
      sequence: i + 1,
      category_id: order.category_id,
    }));

    updateProductSequence(token, "Category", JSON.stringify(data));
  };

  // Function to handle editing
  const handleEdit = (item) => {
    // console.log(item);
    navigate("/update-product", {
      state: {
        productDetails: item,
      },
    });
  };

  const handleAddProductDetails = (item) => {
    // console.log(item);
    navigate("/addon-product", {
      state: {
        productDetails: item,
      },
    });
  };

  // Function to handle editing
  const handleSpecification = (item) => {
    // console.log(item);
    navigate("/specification", {
      state: {
        productDetails: item,
      },
    });
  };

  const handleStatusChange = (data) => {
    if (data.status === "INACTIVE") {
      data.status = "ACTIVE";
    } else {
      data.status = "INACTIVE";
    }
    // console.log(data);
    updateProductData(token, data).then((res) => {
      fetchProducts();
      // console.log(res);
    });
  };

  const handleInStock = (data) => {
    data.in_stock = !data.in_stock;

    // console.log(data);
    updateProductData(token, data).then((res) => {
      fetchProducts();
      // console.log(res);
    });
  };

  return (
    <div>
      {isLoading ? (
        // Conditional rendering of loader
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <div className="ibox">
            <div className="ibox-body m-3">
              <Link to="/add-product">
                <div
                  className={`sticky-button ${isButtonActive ? "active" : ""}`}
                  onClick={() => toggleButton()}
                  // data-toggle="modal"
                  // data-target="#addSubCategoryModal"
                >
                  <i className="fa fa-plus"></i>
                </div>
              </Link>

              {/* <div className="d-flex justify-content-center my-3">
            <div className="floating-notification fw-light">
              NOTE - For Sequence Change You just have to Drag the Rows...!
            </div>
          </div> */}
              <DragDropContext
              // onDragEnd={(result) =>
              //   moveRow(result.source.index, result.destination.index)
              // }
              >
                <Droppable droppableId="Products">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <table
                        className="table table-striped table-bordered table-hover product-table coupon-table"
                        cellSpacing="0"
                        width="100%"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Product</th>
                            <th>Category</th>
                            <th>Image</th>
                            <th>Price</th>
                            <th>Action</th>
                            <th>Live</th>
                            <th>In Stock</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productsData.map((item, i) => (
                            <Draggable
                              key={item.product_id}
                              draggableId={item.product_id.toString()}
                              index={i}
                            >
                              {(provided) => (
                                <TableRow
                                  provided={provided}
                                  key={i}
                                  index={i}
                                  item={item}
                                  //   moveRow={moveRow}
                                  //   handleSequenceChange={handleSequenceChange}
                                  handleEdit={handleEdit}
                                  handleAddProductDetails={
                                    handleAddProductDetails
                                  }
                                  handleSpecification={handleSpecification}
                                  handleStatusChange={handleStatusChange}
                                  handleInStock={handleInStock}
                                />
                              )}
                            </Draggable>
                          ))}
                        </tbody>
                      </table>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {count > itemsPerPage ? (
                <div className="d-flex justify-content-between my-3">
                  <div className="fw-light">
                    Showing{" "}
                    {productsData.length > 0 ? startIndex + 1 : startIndex} to{" "}
                    {Math.min(endIndex, count)} of {count} entries
                  </div>
                  <div>
                    <ReactPaginate
                      previousLabel={
                        <button
                          type="button"
                          className="btn btn-outline-secondary w-100"
                        >
                          Previous
                        </button>
                      }
                      nextLabel={
                        <button
                          type="button"
                          className="btn btn-outline-secondary w-100"
                        >
                          Next
                        </button>
                      }
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={Math.ceil(count / itemsPerPage)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={({ selected }) => {
                        setPage(selected + 1);
                        setCurrentPage(selected);
                        getAllProducts(token, selected + 1, restaurantId).then(
                          (res) => {
                            setProductsData(res.data.results);
                          }
                        );
                      }}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={
                        "active-class btn btn-outline-secondary w-100 fw-bold"
                      }
                      pageClassName={
                        "page-class btn btn-outline-secondary w-100"
                      }
                    />
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const TableRow = ({
  provided,
  item,
  index,
  handleEdit,
  handleSpecification,
  handleStatusChange,
  handleAddProductDetails,
  handleInStock,
  //   moveRow,
  //   handleSequenceChange,
}) => (
  <tr
    key={index}
    className="draggingItem tr"
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
    style={{ position: "relative", cursor: "auto" }}
  >
    <td>{index + 1}</td>
    <td>{item.product_name}</td>
    <td>{item.category_name}</td>
    <td>
      <img src={item.product_url} alt="product" />
    </td>
    <td>
      <i className="fa fa-inr"></i>
      {(item.price * 1).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
    </td>
    <td>
      {item.status === "ACTIVE" ? (
        <i
          className="fa fa-pencil popup me-3"
          onClick={() => handleEdit(item)}
        ></i>
      ) : (
        <i className="fa fa-pencil disabled-pencil me-3"></i>
      )}

      <i
        className="fa fa-puzzle-piece me-3"
        onClick={() => handleAddProductDetails(item)}
      ></i>

      <i
        className="fa fa-list-alt "
        onClick={() => handleSpecification(item)}
      ></i>
    </td>
    <td>
      <div className="form-check product-check form-switch d-flex justify-content-center fs-4">
        <input
          className="form-check-input product-input"
          type="checkbox"
          id={`flexSwitchCheckChecked_${item.product_id}`}
          value={item.status === "ACTIVE" ? true : false}
          checked={item.status === "ACTIVE" ? true : false}
          onChange={() => handleStatusChange(item)}
        />
      </div>
    </td>
    <td>
      <div className="form-check product-check form-switch d-flex justify-content-center fs-4">
        <input
          className="form-check-input product-input"
          type="checkbox"
          id={`flexSwitchCheckChecked_${item.product_id}`}
          value={item.in_stock}
          checked={item.in_stock}
          onChange={() => handleInStock(item)}
        />
      </div>
    </td>
  </tr>
);
