import React, { useEffect, useState } from "react";
import update from "immutability-helper";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addSubCategoryData,
  deleteSubCategoryData,
  getMasterCategory,
  getSubCategory,
  updateSubCategoryData,
  updateSubCategorySequence,
} from "../../service/ecommerce.service";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Loader from "../layout/Loader";

export default function SubCategory() {
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [isButtonActive, setIsButtonActive] = useState(false); // Added state for button
  const token = useSelector((state) => state.status.token.token);
  const restaurantId = useSelector((state) => state.admin.admin.restaurant);
  const [masterCategory, setMasterCategory] = useState();
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  // console.log(subCategoryData[0]);

  // Function to toggle button's active state
  const toggleButton = () => {
    reset1({ category_for: "", subcategoryName1: "", uploadImage: true });
    setUploadImage(null);
    setIsButtonActive(!isButtonActive);
  };

  const fetchSubCategory = () => {
    // setIsLoading(true);
    getSubCategory(token, restaurantId).then((res) => {
      setSubCategoryData(res.data);
      // console.log(res.data);
      setIsLoading(false);
    });
    getMasterCategory(token, restaurantId).then((res) => {
      setMasterCategory(res.data);
    });
  };

  // console.log(masterCategory);

  useEffect(() => {
    window.scrollTo(0, 1);
    fetchSubCategory();
    // eslint-disable-next-line
  }, []);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(0);
  };

  const filteredOrders = subCategoryData.filter(
    (item) =>
      item.category
        .toLowerCase()
        .includes(searchQuery.replace(/\s+/g, " ").trim().toLowerCase()) ||
      item.available_time
        .toLowerCase()
        .includes(searchQuery.replace(/\s+/g, " ").trim().toLowerCase()) ||
      item.category_for
        .toLowerCase()
        .includes(searchQuery.replace(/\s+/g, " ").trim().toLowerCase())
  );

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(0);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentOrders = filteredOrders.slice(startIndex, endIndex);

  const handleSequenceChange = (e, rowIndex) => {
    const startIndex = currentPage * itemsPerPage;
    const updatedRowIndex = startIndex + rowIndex;

    const updatedOrders = update(subCategoryData, {
      [updatedRowIndex]: { sequenceNumber: { $set: e.target.value } },
    });

    setSubCategoryData(updatedOrders);

    const data = updatedOrders.map((order, i) => ({
      sequence: i + 1,
      category_id: order.category_id,
    }));

    updateSubCategorySequence(token, "Category", JSON.stringify(data));
  };

  const moveRow = (dragIndex, hoverIndex) => {
    const startIndex = currentPage * itemsPerPage;
    const updatedDragIndex = startIndex + dragIndex;
    const updatedHoverIndex = startIndex + hoverIndex;

    const draggedRow = subCategoryData[updatedDragIndex];
    const updatedOrders = update(subCategoryData, {
      $splice: [
        [updatedDragIndex, 1],
        [updatedHoverIndex, 0, draggedRow],
      ],
    });

    setSubCategoryData(updatedOrders);

    const data = updatedOrders.map((order, i) => ({
      sequence: i + 1,
      category_id: order.category_id,
    }));

    updateSubCategorySequence(token, "Category", JSON.stringify(data));
  };

  const {
    register,
    handleSubmit: handleSubmitUpdate,
    formState: { errors },
    clearErrors,
  } = useForm();

  const {
    register: register1,
    handleSubmit: handleSubmitAdd,
    reset: reset1,
    setError: setError1,
    formState: { errors: errors1 },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmitDelete,
    watch,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm();

  const [editedItem, setEditedItem] = useState(null);
  const [deletedItem, setDeletedItem] = useState(null);
  const [editedSubcategoryName, setEditedSubcategoryName] = useState("");
  const [editedImage, setEditedImage] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [selectedMasterCategory, setSelectedMasterCategory] = useState(null);

  // Function to handle editing
  const handleEdit = (item) => {
    reset2({ confirmSubcategory: "" });
    setEditedItem(item);
    setEditedSubcategoryName(item.category);
    setEditedImage(item.category_url);
    clearErrors();
  };

  // Function to handle editing
  const handleDelete = (item) => {
    reset2({ confirmSubcategory: "" });
    setDeletedItem(item);
  };

  // Function to handle changes in the input fields for Subcategory Name
  const handleEditChangeSubcategoryName = (e) => {
    setEditedSubcategoryName(e.target.value);
  };

  const updateImage = (event) => {
    const input = event.target;
    if (input.files && input.files[0]) {
      const reader = new FileReader();

      setEditedImage(input.files[0]);

      reader.onload = function (e) {
        // Update the src attribute of the displayed image
        document.getElementById("displayedImage").src = e.target.result;
        // Set the editedImage state with the new image data
      };

      // Read the chosen file as a data URL
      reader.readAsDataURL(input.files[0]);

      input.value = "";
    }
  };

  const addImage = (event) => {
    const input = event.target;
    if (input.files && input.files[0]) {
      const reader = new FileReader();

      setUploadImage(input.files[0]);

      reader.onload = function (e) {
        // Update the src attribute of the displayed image
        document.getElementById("displayedImageAdd").src = e.target.result;
        // Set the editedImage state with the new image data
        setError1("uploadImage", { type: "manual", message: "" });
      };

      // Read the chosen file as a data URL
      reader.readAsDataURL(input.files[0]);
    }
  };

  const handleUpdateImageClick = () => {
    // Trigger the click event on the file input when the displayed image is clicked
    document.getElementById("imageInputUpdate").click();
  };

  const handleAddImageClick = () => {
    // Trigger the click event on the file input when the displayed image is clicked
    document.getElementById("imageInputAdd").click();
  };

  const [subcategoryModalShow, setSubcategoryModalShow] = useState(false);

  // const hideSubcategoryModal = () => {
  //   setSubcategoryModalShow(true);
  // };

  const showSubcategoryModal = () => {
    setSubcategoryModalShow(false);
  };

  const updateData = () => {
    editedItem.category = editedSubcategoryName;
    editedItem.image = editedImage;
    let data = editedItem;
    // console.log(data);
    updateSubCategoryData(token, data).then((res) => {
      // console.log(res.data);
      toast.success("Subcategory Updated...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      reset2({ confirmSubcategory: "" });
      document.getElementById("closeUpdateModal").click();
      fetchSubCategory();
    });
    fetchSubCategory();
  };

  // console.log(selectedMasterCategory)

  const addData = (item) => {
    // console.log(item);
    // console.log(selectedMasterCategory);

    const data = {
      category: item.subcategoryName1,
      restaurant_id: selectedMasterCategory.restaurant_id,
      image: uploadImage,
      category_for: item.category_for,
      sequence: subCategoryData?.[0] ? (subCategoryData[0].sequence - 1) : 1,
      master_category: selectedMasterCategory.master_category_id,
      available_time: "All Time",
    };

    // console.log(data);

    addSubCategoryData(token, data).then((res) => {
      // console.log(res.data);
      toast.success("Subcategory Added...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      reset2({ confirmSubcategory: "" });

      document.getElementById("closeAddModal").click();
      fetchSubCategory();
    });
    reset1({ category_for: "", subcategoryName1: "", uploadImage: true });
    setUploadImage(null);
  };

  const deleteData = () => {
    // console.log(data)
    // console.log(deletedItem)
    deleteSubCategoryData(token, deletedItem.category_id).then((res) => {
      // console.log(res);
      toast.error("Subcategory Deleted...!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
      });
      reset2({ confirmSubcategory: "" });
      document.getElementById("closeDeleteModal").click();
      fetchSubCategory();
    });
  };

  const handleSelectMasterCategory = (item) => {
    // console.log(item);
    setError1("category_for", { type: "manual", message: "" });
    setSelectedMasterCategory(item);
  };

  return (
    <div>
      {isLoading ? (
        // Conditional rendering of loader
        <Loader />
      ) : (
        <div className="page-content ms-3 fade-in-up">
          <ToastContainer position="top-center" className="mt-4" limit={1} />

          <div className="ibox">
            <div className="ibox-body m-3">
              <div
                className={`sticky-button ${isButtonActive ? "active" : ""}`}
                onClick={() => toggleButton()}
                data-toggle="modal"
                data-target="#addSubCategoryModal"
              >
                <i className="fa fa-plus"></i>
              </div>

              {/* <!-- Edit Modal --> */}

              <div className="container ">
                <div
                  className="modal fade"
                  id="editSubCategoryModal"
                  role="dialog"
                >
                  <div
                    className={`modal-dialog ${
                      subcategoryModalShow ? "fade-out-background" : ""
                    }`}
                    id="subcategoryModal"
                  >
                    {/* <!-- Edit Modal content--> */}
                    <form
                      id="editForm"
                      data-form-label="update"
                      onSubmit={handleSubmitUpdate(updateData)}
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title fw-bold">
                            UPDATE SUBCATEGORY
                          </h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            id="closeUpdateModal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body">
                          {/* <p>Some text in the modal.</p> */}
                          {editedItem && (
                            <div>
                              <div>
                                <label>
                                  Category <i className="fa fa-asterisk"></i>
                                </label>

                                <input
                                  value={editedItem.category_for}
                                  {...register("category", {
                                    required: "Please Enter Category",
                                  })}
                                />
                                {errors.category && (
                                  <p className="text-danger">
                                    {errors.category.message}
                                  </p>
                                )}
                              </div>

                              <div>
                                <label>
                                  Subcategory Name{" "}
                                  <i className="fa fa-asterisk"></i>
                                </label>

                                <input
                                  value={editedSubcategoryName}
                                  {...register("subCategoryName", {
                                    required: "Please Enter Subcategory Name",
                                  })}
                                  onChange={handleEditChangeSubcategoryName}
                                />
                                {errors.subCategoryName && (
                                  <p className="text-danger position-absolute">
                                    {errors.subCategoryName.message}
                                  </p>
                                )}
                              </div>

                              <div>
                                <label>
                                  Subcategory Image{" "}
                                  <i className="fa fa-asterisk"></i>
                                </label>

                                {/* Hidden file input */}
                                <input
                                  type="file"
                                  id="imageInputUpdate"
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  onChange={updateImage}
                                />

                                {/* Displayed image */}

                                <img
                                  src={editedImage}
                                  id="displayedImage"
                                  alt={editedItem.category}
                                  onClick={() => handleUpdateImageClick()}
                                  // {...register("editedImage", {
                                  //   required: "Please Add Image",
                                  // })}
                                  // data-bs-dismiss="#editSubCategoryModal"
                                  // data-bs-target="#addImageModal"
                                  // data-bs-toggle="modal"
                                  // onClick={hideSubcategoryModal}
                                />
                                {/* {errors.editedImage && (
                            <p className="text-danger">
                              {errors.editedImage.message}
                            </p>
                          )} */}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button
                            type="submit"
                            className="btn btn-default"
                            // data-dismiss="modal"
                            // onClick={updateData}
                          >
                            UPDATE
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* <!-- Add Modal --> */}

              <div className="container ">
                <div
                  className="modal fade"
                  id="addSubCategoryModal"
                  role="dialog"
                >
                  <div className="modal-dialog">
                    {/* <!-- Add Modal content--> */}
                    <form
                      id="addForm"
                      data-form-label="add"
                      onSubmit={handleSubmitAdd(addData)}
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title fw-bold">
                            ADD SUBCATEGORY
                          </h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            id="closeAddModal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body">
                          {/* <p>Some text in the modal.</p> */}

                          <div>
                            <div>
                              <label>
                                Category <i className="fa fa-asterisk"></i>
                              </label>

                              <select
                                className="form-select"
                                defaultValue="Select Category"
                                {...register1("category_for", {
                                  required: "Please Select Category",
                                })}
                                onChange={(e) =>
                                  handleSelectMasterCategory(
                                    masterCategory[e.target.selectedIndex - 1]
                                  )
                                }
                              >
                                <option
                                  disabled
                                  value=""
                                  // selected
                                >
                                  Select Category
                                </option>
                                {masterCategory &&
                                  masterCategory.map((item, i) => (
                                    <option
                                      key={i}
                                      value={item.master_category_name}
                                    >
                                      {item.master_category_name}
                                    </option>
                                  ))}
                              </select>
                              {errors1.category_for && (
                                <p className="text-danger position-absolute">
                                  {errors1.category_for.message}
                                </p>
                              )}
                            </div>

                            <div>
                              <label className="mt-3">
                                Subcategory Name{" "}
                                <i className="fa fa-asterisk"></i>
                              </label>

                              <input
                                {...register1("subcategoryName1", {
                                  required: "Please Enter Subcategory Name",
                                })}
                              />
                              {errors1.subcategoryName1 && (
                                <p className="text-danger position-absolute">
                                  {errors1.subcategoryName1.message}
                                </p>
                              )}
                            </div>

                            <div>
                              <label>
                                Subcategory Image{" "}
                                <i className="fa fa-asterisk"></i>
                              </label>

                              {/* Hidden file input */}
                              <input
                                type="file"
                                id="imageInputAdd"
                                accept="image/*"
                                defaultValue={uploadImage}
                                {...register1("uploadImage", {
                                  required: "Please Upload Image",
                                })}
                                style={{ display: "none" }}
                                onChange={addImage}
                              />

                              {/* Displayed image */}

                              <img
                                src={
                                  uploadImage ||
                                  "/assets/img/custom/uploadImage.png"
                                }
                                id="displayedImageAdd"
                                alt="Upload"
                                // display="/assets/img/custom/uploadImage.png"
                                onClick={() => handleAddImageClick()}
                                // data-bs-dismiss="#editSubCategoryModal"
                                // data-bs-target="#addImageModal"
                                // data-bs-toggle="modal"
                                // onClick={hideSubcategoryModal}
                              />

                              {errors1.uploadImage && (
                                <p className="text-danger position-absolute">
                                  {errors1.uploadImage.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="submit"
                            className="btn btn-default"
                            // data-dismiss="modal"
                            // onClick={addData}
                          >
                            ADD
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* <!-- Delete Modal --> */}
              <div className="container ">
                <div
                  className="modal fade"
                  id="deleteSubCategoryModal"
                  role="dialog"
                >
                  <div className="modal-dialog">
                    {/* <!-- Delete Modal content--> */}
                    <form
                      id="deleteForm"
                      data-form-label="delete"
                      onSubmit={handleSubmitDelete(deleteData)}
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title fw-bold">WARNING</h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            id="closeDeleteModal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body">
                          <p className="fs-5">
                            After delete this 'Subcategory', All data related to
                            this 'Subcategory' will be deleted.
                          </p>
                          <p className="fs-5 fw-bold">
                            You still want to delete this 'Subcategory'!
                          </p>
                          <p className="fw-light">
                            Confirm your selected 'Subcategory' to Delete
                          </p>
                          {deletedItem && (
                            <div>
                              <input
                                hidden
                                value={deletedItem.category}
                                {...register2("subcategoryDelete")}
                              />
                              <input
                                {...register2("confirmSubcategory", {
                                  required: "Please Enter Subcategory",
                                  validate: (value) => {
                                    if (watch("subcategoryDelete") !== value) {
                                      return "Sorry! Your Selected 'Subcategory' do not Match";
                                    }
                                  },
                                })}
                              />
                              {errors2.confirmSubcategory && (
                                <p className="text-danger position-absolute">
                                  {errors2.confirmSubcategory.message}
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button
                            type="submit"
                            className="btn btn-default"
                            // data-dismiss="modal"
                            // onClick={updateData}
                          >
                            DELETE
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* Second Modal */}

              <div
                className="modal fade"
                id="addImageModal"
                aria-hidden="true"
                aria-labelledby="exampleModalToggleLabel2"
                tabIndex="-1"
              >
                <div className="modal-dialog modal-dialog-centered w-25">
                  <div className="modal-content">
                    <div className="modal-header add-image">
                      <h5 className="modal-title" id="exampleModalToggleLabel2">
                        Add Image!
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => showSubcategoryModal()}
                      ></button>
                    </div>
                    <div className="modal-body">
                      Hi.
                      {/* Hidden file input */}
                      {/* <input
                    type="file"
                    id="imageInput"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={updateImage}
                  /> */}
                    </div>
                    {/* <div className="modal-footer">
                    <button
                      className="btn btn-default"
                      data-bs-target="#editSubCategoryModal"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                    >
                      Back to first
                    </button>
                  </div> */}
                  </div>
                </div>
              </div>
              {/* End Second Modal */}

              <div className="d-flex justify-content-between my-3">
                <div className="fw-light">
                  Show{" "}
                  <select
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                    className="fw-light"
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="20">25</option>
                    <option value="50">30</option>
                  </select>{" "}
                  Results
                </div>
                <div className="floating-notification fw-light">
                  NOTE - For Sequence Change You just have to Drag the Rows...!
                </div>
                <div className="fw-light">
                  Search :{" "}
                  <input
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="btn-outline-secondary"
                  />
                </div>
              </div>

              <DragDropContext
                onDragEnd={(result) =>
                  moveRow(result.source.index, result.destination.index)
                }
              >
                <Droppable droppableId="subCategories">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <table
                        className="table table-striped table-bordered table-hover"
                        cellSpacing="0"
                        width="100%"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Category</th>
                            <th>Sub Category</th>
                            <th>Image</th>
                            {/* <th>Availability</th> */}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentOrders.map((item, i) => (
                            <Draggable
                              key={item.category_id}
                              draggableId={item.category_id.toString()}
                              index={i}
                            >
                              {(provided) => (
                                <TableRow
                                  provided={provided}
                                  key={i}
                                  index={i}
                                  item={item}
                                  moveRow={moveRow}
                                  handleSequenceChange={handleSequenceChange}
                                  handleEdit={handleEdit}
                                  handleDelete={handleDelete}
                                />
                              )}
                            </Draggable>
                          ))}
                        </tbody>
                      </table>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {subCategoryData.length > itemsPerPage ? (
                <div className="d-flex justify-content-between my-3">
                  <div className="fw-light">
                    Showing{" "}
                    {filteredOrders.length > 0 ? startIndex + 1 : startIndex} to{" "}
                    {Math.min(endIndex, filteredOrders.length)} of{" "}
                    {filteredOrders.length} entries
                  </div>
                  <div>
                    <ReactPaginate
                      previousLabel={
                        <button
                          type="button"
                          className="btn btn-outline-secondary w-100"
                        >
                          Previous
                        </button>
                      }
                      nextLabel={
                        <button
                          type="button"
                          className="btn btn-outline-secondary w-100"
                        >
                          Next
                        </button>
                      }
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={Math.ceil(
                        filteredOrders.length / itemsPerPage
                      )}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={({ selected }) => setCurrentPage(selected)}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={
                        "active-class btn btn-outline-secondary w-100 fw-bold"
                      }
                      pageClassName={
                        "page-class btn btn-outline-secondary w-100"
                      }
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const TableRow = ({
  provided,
  item,
  index,
  handleEdit,
  handleDelete,
  moveRow,
  handleSequenceChange,
}) => (
  <tr
    className="draggingItem tr"
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
  >
    <td>{index + 1}</td>
    <td>{item.category_for}</td>
    <td>{item.category}</td>
    <td>
      <img src={item.category_url} alt={item.category} />
    </td>
    {/* <td>{item.available_time}</td> */}
    <td>
      <Link
        to="#"
        onClick={() => handleEdit(item)}
        data-toggle="modal"
        data-target="#editSubCategoryModal"
      >
        <i className="fa fa-pencil popup me-3"></i> 
      </Link>
      <Link
        to="#"
        onClick={() => handleDelete(item)}
        data-toggle="modal"
        data-target="#deleteSubCategoryModal"
      >
        <i className="fa fa-trash popup ms-3"></i>
      </Link>
    </td>
    {/* <td>
      <i
        className="fa fa-bars"
        type="text"
        value={item.sequenceNumber}
        onChange={(e) => handleSequenceChange(e, index)}
      ></i>
    </td> */}
  </tr>
);
